@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');


@layer base {
  :root {
    --background: #F3F6FA;
    --primary: 110 19 2;
    --text-primary: #40292F;
    --text-secondary: #A9B5C6;
    --border: #9DA8B8;
    --delete: #DE3C4B;
    --status-approved: 0 153 51;
    --status-arrived: 51 102 255;
    --status-exited: 255 204 0;
    --status-updated: 153 51 204;
    --status-rejected: 219 22 47;
    --status-pending: 204 85 0;
    --status-completed: 34 139 34; 
    --status-expired: 128 128 128;
    }
}

.App {
  text-align: center;
  font-family: 'Inter', sans-serif;
  background-color: var(--background);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
}

.authLayout {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.authLayout img {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.authCard {
  padding: 2rem 2rem;
  margin: 3vw 10vw;
  display: flex;
  flex-direction: column;
  max-width: 30vw;
  width: 100%;
  background-color: #ffffff;
  border-radius: 0.5rem;
}


.valetCard {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
  margin-left: 5vw;
  margin-right: 5vw;
  display: flex;
  flex-direction: column;
  max-width: 30vw;
  width: 100%;
  background-color: #ffffff;
  border-radius: 0.5rem;
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
  .valetCard {
    margin: 2rem;
    max-width: 90vw;
  }
}

.venue-selection {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

/* Form Styles */
form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.FormGroup {
  width: 100%;
  margin-bottom: 20px;
}

.FormRow {
  width: 100%;
  margin-bottom: 15px;
}

.input-field, .StripeElement {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}

.StripeElement {
  margin-top: 10px;
  padding: 10px;
  background: #f4f4f9;
}
.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.7); /* Darker background */
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.react-confirm-alert {
  width: 400px;
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  position: relative;
}
.react-confirm-alert-button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px; 
}

.react-confirm-alert-button-group button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.react-confirm-alert-button-group button:first-of-type {
  margin-left: auto;
  background: #d33;
  color: #fff;
}

.react-confirm-alert-button-group button:last-of-type {
  background: #aaa;
  color: #fff;
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
 
  .FormRow {
      flex-direction: column;
      align-items: stretch;
      margin-bottom: 1rem;
  }

  .input-field, .StripeElement{
      margin-right: 0;
      margin-bottom: 8px; 
      padding: 8px;
      font-size: 14px;
  }

  .button-field {
      width: 100%; /* Make the button full-width for better UX on mobile */
      font-size: 14px;
      height: auto; /* Adjust height to fit content */
      padding: 10px 0;
  }

}
input[type="checkbox"] + span {
  cursor: pointer;
  color: #007bff;
  text-decoration: underline;
}
.Summary p {
  margin-left: 20px; /* Adjust the indentation as needed */
}

.Summary .amount {
  float: right;
}

.summary-divider {
  border: none;
  height: 1px;
  color: #ccc;
  background-color: #ccc;
  margin: 10px 0;
}
.coupon-box {
  border: 2px dashed #ccc;
  padding: 10px;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.coupon-box span {
  margin-right: 10px;
}

.coupon-desc {
  color: #666;
  font-size: smaller;
}

.authInputBg {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.autocomplete-root {
  position: relative;
}

.autocomplete-list {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
}

@keyframes wobble {
  0%, 100% {
    transform: translateX(0);
  }
  15% {
    transform: translateX(-10px) rotate(-5deg);
  }
  30% {
    transform: translateX(10px) rotate(5deg);
  }
  45% {
    transform: translateX(-10px) rotate(-3deg);
  }
  60% {
    transform: translateX(10px) rotate(3deg);
  }
  75% {
    transform: translateX(-10px) rotate(2deg);
  }
  90% {
    transform: translateX(10px) rotate(-2deg);
  }
}

/* The container must be positioned relative: */
.custom-select {
  position: relative;
  font-family: Arial, sans-serif;
}

.select-selected {
  background-color: rgb(197, 204, 212);
}

/* Style the arrow inside the select element: */
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

/* Point the arrow upwards when the select box is open (active): */
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

/* style the items (options), including the selected item: */
.select-items div,.select-selected {
  color: #ffffff;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
}

/* Style items (options): */
.select-items {
  position: absolute;
  background-color: DodgerBlue;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

/* Hide the items when the select box is closed: */
.select-hide {
  display: none;
}

.select-items div:hover, .same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  width: 70%;
}

.version-tag {
  position: fixed;
    bottom: 0;
    width: 100%;
}
.status-back-label {
  z-index: -1;
  position: absolute;
}

//coming soon
.coming-soon-container {
  background-color: var(--background);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.coming-soon-content {
  background-color: white;
  border: 1px solid var(--border);
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  max-width: 400px;
}

.coming-soon-content h1 {
  color: var(--primary);
  font-size: 2rem;
  margin-bottom: 1rem;
}

.coming-soon-content p {
  color: var(--text-primary);
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.coming-soon-container::before {
  content: '';
  background: linear-gradient(135deg, rgba(255, 204, 0, 0.6), rgba(51, 102, 255, 0.6));
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.2;
  z-index: -1;
}

//Terms Modal
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 800px;
  max-height: 80vh; 
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
}


.modal-content h2 {
  color: var(--text-primary);
  margin-bottom: 20px;
}

.modal-content p, .modal-content ol {
  color: var(--text-secondary);
  line-height: 1.6;
}

.modal-content ol li {
  margin-bottom: 10px;
}


.close-modal-btn {
  background-color: var(--primary);
  border: 2px solid var(--text-primary);
  color: var(--text-primary);
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  display: inline-block;
  float: right;
}

.close-modal-btn:hover {
  background-color: var(--text-primary);
  color: var(--background);
}

.payment-form-container {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.payment-form-header {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

.payment-form .form-group {
  margin-bottom: 15px;
}

.payment-form label {
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
}

.payment-form input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.payment-form .form-agreement {
  font-size: 12px;
  color: #555;
  margin-bottom: 20px;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}

.submit-button {
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
